<template>
  <div v-if="invoiceDetail !== null" class="mt-4">
    <div  class="relative card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full ">
      <div class=" text-text2 heading-4 absolute left-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="$router.push({name: 'Emailconfiguration'})">
        <i class="fa-solid fa-arrow-left"></i>
      </div>
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center ml-12">Email Configuration</h1>
      </div>
      <div class="absolute right-4 flex items-center top-1.5">
        <!-- <div class=" text-error heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click="removeConfirm = true">
          <i class="fas fa-trash"></i>
        </div> -->
        <div class=" text-primary heading-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl ml-2" @click="updateInvoice()">
          <i class="fa-solid fa-pen-to-square"></i>
        </div>
      </div>
    </div>
    <div class="py-2">
      <div class="card bg-white rounded-xl p-4 my-2">
        <p class=" text-gray4 heading-4 font-bold mb-4">Basic Email Details</p>
        <div class="text-gray4 lg:grid grid-cols-12 gap-4" >
          <div class="col-span-4 2xl:col-span-4">
            <p>Status</p>
            <p  class="" :class="invoiceDetail.isActive ? 'text-text2' : 'text-error'">Email Configuration is {{invoiceDetail.isActive ? 'Active' : 'Inactive'}}</p>
          </div>
          <div class="col-span-4 2xl:col-span-4">
            <p>Email Label</p>
            <p class="text-text1">{{invoiceDetail.emailLabel}}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
         <div class="lg:grid grid-cols-12 gap-4">
            <div class="col-span-4 2xl:col-span-4">
              <div class="card bg-white rounded-xl p-4 mb-4">
                <p class=" text-gray4 heading-4 font-bold mb-4">Email Login Credentials</p>
                    <div class=" flex justify-between">
                      <div class=" mb-4">
                        <p class="text-gray4">Email</p>
                        <p class=" text-text1">{{invoiceDetail.emailId}}</p>
                      </div>
                      <div class=" mb-4">
                          <p class="text-gray4 text-right">Password</p>
                          <p class="font-bold text-text1 text-right">******</p>
                      </div>
                    </div>
              </div>
              <div class="card bg-white rounded-xl p-4 mb-4">
                <p class=" text-gray4 heading-4 font-bold mb-4">SMTP Setting (Used to send Email)</p>
                <div class=" mb-4">
                  <p class="text-gray4">SMTP Host</p>
                  <p  class=" text-text2">{{invoiceDetail.smtpHost}}</p>
                </div>
                <div class="text-gray4 flex justify-between mb-4">
                  <div>
                    <p>SMTP Port</p>
                    <p class="text-text2">{{invoiceDetail.smtpPort}}</p>
                  </div>
                  <div>
                    <p>SMTP Encryption</p>
                    <p class="text-text1 text-right">{{invoiceDetail.smtpEncryption}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class=" col-span-8 2xl:col-span-6 mb-10">
              <div class="card bg-white rounded-xl p-4 mb-4">
                <p class=" text-gray4">Email Signature</p>
                <p v-if="invoiceDetail.emailSignature !== ''" class="text-text2" v-html="invoiceDetail.emailSignature"></p>
                <p v-if="invoiceDetail.emailSignature === ''" class="text-text2">-</p>
              </div>
            </div>
        </div>
    </div>
    <!-- <div v-if="removeConfirm" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-bold">
          <p class="heading-3 text-text1">Are you sure?</p>
        </div>
        <div  class="mb-5 font-bold">
          <p class="heading-6 text-text1 mb-4">Once deleted cannot undone.</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="removeConfirm = false"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="deleteAddress"/>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import MyJobApp from "@/api/MyJobApp.js";
// import Button from '@/View/components/globalButton.vue'
export default {
  name: "customer",
  components: {
    // Button,
  },
  data() {
    return {
      isCustomerPermission: FilterPermissions('customer'),
      canPaymentRecordPermission: FilterPermissions('record_payment'),
      canEmailSendPermission: FilterPermissions('invoice_email'),
        invoiceDetail: null,
        titleObj: [
          {title: 'Compnay Name', icon: '', sortName: ''},
          {title: 'Transaction Id', icon: '', sortName: ''},
          {title: 'Payment Method', icon: '', sortName: ''},
          {title: 'Amount', icon: '', sortName: ''},
          {title: 'Recorded By', icon: '', sortName: ''},
          {title: 'Date', icon: '', sortName: ''},
        ],
        finalTaxList: [],
        invoicePaymentRecords: [],
        taxesAmmount: 0,
        vissibleTaxId: 0,
        selectedItemDetails: null,
        itemdetailShow: false,
            removeConfirm: false,
        itemBreakDonwShow: false,
        showMenuList: false,
        isSendInvoiceEmail: false,
    };
  },
  created() {},
  mounted() {
    this.getInvoiceDetails()
    // this.$root.$on('confirmBoxHandler', (response) => {
    //   document.body.style = 'overflow: visible;'
    //     if (response) {
    //       this.deleteAddress()
    //     }
    //     this.removeConfirm = false
    // })
  },
  methods: {
     updateInvoice () {
      this.$router.push({name: 'EmailconfigurationEdit', params: {emailId: this.invoiceDetail.emailConfigId}})
    },
    getInvoiceDetails() {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
      MyJobApp.GetEmailConfDetail(
        parseInt(this.$route.params.emailId),
        (response) => {
          this.invoiceDetail = response.Data;
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  },
  beforeDestroy() {
    this.$root.$off("confirmBoxHandler");
  },
};
</script>
<style scoped>
.table_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.name_col {
    min-width: 200px;
    max-block-size: 200px;
}
.des_col {
    min-width: 250px;
    max-block-size: 250px;
}
.pric_col {
    min-width: 100px;
    max-block-size: 100px;
}
.qnty_col {
    min-width: 100px;
    max-block-size: 100px;
}
.amt_col {
    min-width: 100px;
    max-block-size: 100px;
}
</style>